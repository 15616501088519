<script>
import MenuTipoPessoa from "@/components/pessoa/components/include/MenuTipoPessoa"
import Menu from '../menu'
import {list, deletePerson, editManyPerson} from '@/domain/pessoa/services'
import {date, UTooltip, UInput, UPopover} from 'uloc-vue'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu-pessoa'
import listOptions from '../windows/listOptions'
import simpleNewPerson from '../windows/simpleNewPerson'
import {datePtToEn} from '@/utils/date'
import AutocompleteMultiple from "@/components/layout/components/AutocompleteMultiple"
// import searchTags from "@/components/pessoa/helpers/searchTags"
import ListMixin from "@/reuse/list/ListMixin"
import {listName, listStorage} from "../config/list"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import PessoaCadastro from '@/components/pessoa/components/window/Cadastro'
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"

let filters = {
  id: null,
  nome: null,
  tipo: null, // Pessoas / 2 = Organizações
  letra: null,
  tag: null,
  tags: []
}

export default {
  name: 'Pessoas',
  inject: ['mainLayout'],
  mixins: [ListMixin, GlobalPersonMixin],
  components: {
    // AutocompleteMultiple,
    MenuTipoPessoa,
    DefaultListMenu,
    MenuOptions,
    UTooltip,
    UInput,
    PessoaCadastro,
    MenuOptionsItem,
    UPopover
  },
  beforeCreate() {
    document.querySelector('body').classList.add('pessoa-lista-body')
    this.listStorage = listStorage
    this.listName = listName
  },
  data() {
    return {
      listType: 'ativos',
      busca: '',
      filters: JSON.parse(JSON.stringify(filters)),
      filtroLetras: [
        {name: 'Tudo', value: null, active: true},
        {value: 'A', active: false},
        {value: 'B', active: false},
        {value: 'C', active: false},
        {value: 'D', active: false},
        {value: 'E', active: false},
        {value: 'F', active: false},
        {value: 'G', active: false},
        {value: 'H', active: false},
        {value: 'I', active: false},
        {value: 'J', active: false},
        {value: 'K', active: false},
        {value: 'L', active: false},
        {value: 'M', active: false},
        {value: 'N', active: false},
        {value: 'O', active: false},
        {value: 'P', active: false},
        {value: 'Q', active: false},
        {value: 'R', active: false},
        {value: 'S', active: false},
        {value: 'T', active: false},
        {value: 'U', active: false},
        {value: 'V', active: false},
        {value: 'W', active: false},
        {value: 'X', active: false},
        {value: 'Z', active: false}
      ]
    }
  },
  computed: {
    menu() {
      return Menu
    },
    menuDashOptions() {
      return MenuOptions
    }
  },
  created() {
    this.testPersonTag()
  },
  mounted() {
    this.testPersonType(false)
    this.testPersonPaper(false)
    this.parseHashCadastro()
    this.load()
    // console.log(this.mainLayout.activeMenu)
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        this.testPersonType(false)
        this.testPersonPaper(false)
        this.testPersonTag(false)
        this.$nextTick(() => {
          this.load()
        })
      })
    },
    'filters.tags' () {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  destroyed() {
    document.querySelector('body').classList.remove('pessoa-lista-body')
  },
  methods: {
    parseHashCadastro () {
      if (this.$route.hash) {
        const hash = this.$route.hash.replace('#', '')
        if (hash === 'cadastro') {
          this.$nextTick(() => {
            setTimeout(() => {
              this.novo()
            }, 500)
          })
          return false
          //this.load()
        }
      }
      return true
    },
    simpleNewPerson: simpleNewPerson,
    testPersonType (needLoad = true) {
      const atual = this.filters.tipo
      const tag = this.filters.tag
      if (this.$route.query.type && this.$route.query.type === 'organizacoes') {
        this.filters.tipo = 2
      } else if(this.$route.query.type && this.$route.query.type === 'pessoas') {
        this.filters.tipo = 1
      } else {
        this.filters.tipo = null
      }
      this.$nextTick(() => {
        console.log(tag, this.filters.tag)
        if (this.filters.tipo !== atual || (tag && !this.filters.tag)) {
          this.filters.tag = null
          needLoad && this.load()
        }
      })
    },
    testPersonPaper (needLoad = true) {
      if (!this.$route.query.paper) {
        this.filters.tag = null
      }
      console.log(this.$route.query.paper)
      this.filters.tag = this.$route.query.paper
      this.$nextTick(() => {
        needLoad && this.load()
      })
    },
    testPersonTag (needLoad = false) {
      if (this.$route.query.tags) {
        const tags = this.$route.query.tags.split(',')
        this.filters.tags = tags.map(tag => {
          return {
            id: tag,
            name: tag,
            label: tag,
            value: tag
          }
        })
        console.log(tags)
        this.$nextTick(() => {
          needLoad && this.load()
        })
      }
    },
    request({pagination, filter, format}) {

      let data1, data2
      let extraFilters = []

      if (this.filters.data1) {
        if (this.filters.data1.length < 10 || this.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.filters.data1)
        data2 = datePtToEn(this.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.filters.id && extraFilters.push(`&id=${this.filters.id}`)
      this.filters.codigo && extraFilters.push(`&codigo=${this.filters.codigo}`)
      this.filters.tipo && extraFilters.push(`&tipo=${this.filters.tipo}`)
      this.filters.letra && extraFilters.push(`&startWith=${this.filters.letra}`)
      this.filters.tags && extraFilters.push(`&tags=${this.filters.tags.map(t => t.value).join(',')}`)
      this.filters.tag && extraFilters.push(`&tag=${this.filters.tag}`)

      if (format === 'excel' || format === 'xlsx') {
        extraFilters.push('&format=excel')
      } else if(format === 'html') {
        extraFilters.push('&format=html')
      }

      this.table.loading = true
      let _filter = this.filtros
      if (filter && String(filter).length > 0) {
        filter = filter.replace('+', '__or__')
      }
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'name'}&descending=${pagination.descending ? 'true' : 'false'}&search=${filter}${extraFilters.join('')}`)


      list(pagination.rowsPerPage, pagination.page, filtros, format)
          .then((response) => {
            let data = response.data
            this.loading = false
            this.table.loading = false
            if (format === 'excel' || format === 'xlsx') {
              donwloadFile(response)
              return
            } else if(format === 'html') {
              this.leiloesHtmlView(data)
              return
            }
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            let result = data.result
            if (Number(pagination.page) < 2) {
              if (data.hoje && data.hoje.length) {
                data.hoje.map(leilao => {
                  leilao.isToday = true
                  result = result.filter(l => l.id !== leilao.id)
                  result.unshift(leilao)
                })
              }
            }
            this.table.serverData = result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.table.loading = false
            this.loading = false
          })
    },
    load(format = null) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca,
        format: format
      })
    },
    clearFilters() {
      this.filters = JSON.parse(JSON.stringify(filters))
    },
    novo() {
      console.log('New...')
      this.$refs.cadastro.show()
      //this.simpleNewPerson(this.filters.tip)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.busca
      })
    },
    abrir(id) {
      this.$router.push({name: 'pessoa.show', params: {id: id}})
    },
    edit(id) {
      this.$refs.cadastro.show(id)
      // this.leilaoWindow(id)
    },
    excluir (id) {
      this.$uloc.dialog({
        title: 'Confirmar remoção',
        message: 'Você tem certeza que deseja remover este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        deletePerson({id: id})
          .then(response => {
            this.pesquisar()
          }).catch(error => {
            this.alertApiError(error)
        })
      }).catch(() => {})
    },
    listOptions: listOptions,
    buscaPorLetra (letra) {
      this.filtroLetras.map(l => letra === l ? l.active = true : l.active = false)
      this.filters.letra = letra.value
      this.load()
    },
    searchPersonTags(terms, done) {
      searchTags(terms)
          .then(response => {
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.code,
                active: null
              }
            })
            done(result)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    parseTagsValues: function (tags) {
      return tags.map(v => {
        return v.name
      })
    },
    execucaoMassa (acao) {
      this.$uloc.dialog({
        title: 'Ação em massa',
        message: `Tem certeza de que deseja ${acao} essas pessoas?`,
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      })
          .then(() => this.executarAcaoMassa(acao))
          .catch(() => {})
    },
    executarAcaoMassa (acao) {
      const idsPeopleSelected = this.table.selected.map(person => person.id)
      const request = {
        acao,
        pessoas: idsPeopleSelected
      }

      editManyPerson(request)
      .then(response => {
        this.dg('Operação realizada com sucesso.')
        this.pesquisar()
      })
      .catch (error => {
        this.alertApiError(error)
        this.pesquisar()
      })
    }
  },
  meta: {
    title: 'Pessoas',
    name: 'Pessoas'
  }
}
</script>

<template>
    <div class="pessoas-list-container">
      <pessoa-cadastro ref="cadastro" />
      <menu-tipo-pessoa :tipo="filters.tipo">
        <!---<div class="title m-t-lg">FILTRO DE FUNÇÃO</div>
        <div class="textarea-autocomplete" style="max-width: 200px">
          <autocomplete-multiple ref="inputTags" load-on-focus :search="searchPersonTags" :parse-value="parseTagsValues"
                                 v-model="filters.tags" placeholder="Tags"/>
        </div>-->
      </menu-tipo-pessoa>
      <div class="erp-list">
        <div class="pessoa-list-options">
          <e-row>
            <e-col>
              <u-btn @click="novo" label="Adicionar" class="btn-new" no-caps />
            </e-col>
            <e-col class="text-right">
              <e-row>
                <e-col class="flex justify-between content-between">
                  <u-input @keydown.enter="pesquisar" v-model="busca" hide-underline placeholder="Pesquisar" class="input-modern col-grow no-border-radius-right" /> <u-btn @click="pesquisar" flat class="btn-modern b-radius-right no-border-left" icon="search" />
                </e-col>
              </e-row>
            </e-col>
            <!-- COL 2 -->
          </e-row>
          <e-row>
            <e-col>
              <span v-if="table.serverPagination.rowsNumber > 1" class="m-l-xs total-pessoas">{{table.serverPagination.rowsNumber}} pessoas encontradas</span>
              <span v-else-if="table.serverPagination.rowsNumber == 1" class="m-l-xs total-pessoas">{{table.serverPagination.rowsNumber}} pessoa encontrada</span>
              <span v-else class="m-l-xs total-pessoas">Nenhuma pessoa encontrada</span>
            </e-col>
          </e-row>
          <e-row>
            <e-col>
              <ul class="menu-alfabeto">
                <li v-for="filtro in filtroLetras" :key="filtro.value"><a @click="buscaPorLetra(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
              </ul>
            </e-col>
          </e-row>
        </div>
        <div class="erp-list-top-btns">
          <div class="end">
<!--            <u-btn @click="imprimir" title="Imprimir" no-caps size="sm" flat icon="print" icon-type="fa" color="grey-4" class="text-grey-8" style="padding: 1px 8px"/>
            <u-btn @click="load('excel')" title="Exportar para Excel" no-caps size="sm" flat icon="file-excel" icon-type="fa" color="grey-4" class="text-grey-8 m-r-sm" style="padding: 1px 6px"/>-->
<!--            <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8"/>-->
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th style="text-transform: none" v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
              </div>
              <u-tooltip v-if="col.alt">{{col.alt}}</u-tooltip>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>
            <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td key="nome" :props="props">
              <a @click="gotoPerson(props.row)" class="hover-underline">{{ props.row.name }}</a>
            </u-td>
            <u-td key="responsavel" :props="props">
              <div class="def-box box-full" v-if="props.row.owners && Array.isArray(props.row.owners) && props.row.owners.length">
                <span v-for="r in props.row.owners.filter(o => o.enabled)">{{r.owner.name}}</span>
              </div>
              <div v-else></div>
            </u-td>
            <u-td key="classificacao" :props="props">
              <div class="def-box box-full box-green" v-if="props.row.tags && Array.isArray(props.row.tags) && props.row.tags.length">
                <span>{{props.row.tags[0].name}}</span>
                <span class="plus" v-if="props.row.tags.length > 1">+{{props.row.tags.length-1}}</span>
              </div>
            </u-td>
            <u-td key="organizacao" :props="props">
              <span v-if="props.row.organization">{{props.row.organization.name}}</span>
            </u-td>
            <u-td key="email" :props="props">
              <div class="def-box box-simple" v-if="props.row.emails && Array.isArray(props.row.emails) && props.row.emails.length">
                <span>{{props.row.emails[0].email}}</span>
                <span class="plus" v-if="props.row.emails.length > 1">+{{props.row.emails.length-1}}</span>
              </div>
            </u-td>
            <u-td key="telefone" :props="props">
              <div class="def-box box-simple" v-if="props.row.phoneNumbers && Array.isArray(props.row.phoneNumbers) && props.row.phoneNumbers.length">
                <span>{{props.row.phoneNumbers[0].phoneNumber|formataTelefone}}</span>
                <span class="plus" v-if="props.row.phoneNumbers.length > 1">+{{props.row.phoneNumbers.length-1}}</span>
              </div>
            </u-td>
            <u-td key="negocios-fechados" :props="props">
              <span v-if="!props.row.cache || !props.row.cache.negociosFechados">0</span>
              <span v-else>{{ props.row.cache.negociosFechados }}</span>
            </u-td>
            <u-td key="negocios-andamento" :props="props">
              <span v-if="!props.row.cache || !props.row.cache.negociosEmAndamento">0</span>
              <span v-else>{{ props.row.cache.negociosEmAndamento }}</span>
            </u-td>
            <u-td key="status" :props="props">
              <span v-if="props.row.active" class="box-status active">Ativo</span>
              <span class="box-status inactive" v-else>Inativo</span>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                     @excluir="excluir(props.row.id)" :leilao="props.row"/>
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>
        <div v-if="table.selected.length > 0" class="m-t-sm m-b">
          <u-btn no-caps icon="check-square" icon-type="fa" size="sm" push flat icon-style="light" align="left" label="Ações para pessoas selecionadas">
            <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
            <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
              <div class="menu_ menu-btn-leilao">
                <ul>
                  <menu-options-item @click="execucaoMassa('mesclar')" label="Mesclar" close  />
                  <menu-options-item @click="execucaoMassa('excluir')" size="sm" label="Excluir" class="bg-red-1" close  />
                </ul>
                <slot />
              </div>
            </u-popover>
          </u-btn>
        </div>
      </div>
    </div>
</template>
