<template>
  <sec-menu>
    <div class="title">TIPO DE PESSOA</div>
    <ul>
      <li><a @click="$router.replace({name: 'pessoas'})" :class="{active: tipo === null}"><u-icon name="address-card" type="fa" /> Qualquer</a></li>
      <li><a @click="$router.replace({name: 'pessoas', query: {type: 'pessoas'}})" :class="{active: tipo == 1}"><u-icon name="user" type="fa" /> Pessoas</a></li>
      <li><a @click="$router.replace({name: 'pessoas', query: {type: 'organizacoes'}})" :class="{active: tipo == 2}"><u-icon name="building" type="fa" /> Organizações</a></li>
    </ul>

    <div class="title m-t-lg">FUNÇÃO NO SISTEMA</div>
    <ul>
      <li v-for="t in tags" :key="t.value"><a @click="toggleFilter(t.value, $route.query.paper)" :class="{active: $route.query.paper === t.value}">{{ t.label }}</a></li>
    </ul>
    <slot />
  </sec-menu>
</template>

<script>
import SecMenu from "@/components/layout/components/SecMenu"
import {listTags} from '@/domain/pessoa/services'
export default {
  name: "MenuTipoPessoa",
  props: ['tipo'],
  data () {
    return {
      tags: []
    }
  },
  components: {SecMenu},
  mounted () {
    this.loadtags()
    toggleFilter($route.query.tags, $route.query.tags)
  },
  methods: {
    loadtags() {
      listTags(100, 1, [])
      .then((response) => {
        this.tags = response.data.result.map((v) => {
          return {
            label: v.name,
            value: v.code
          }
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    toggleFilter(param, current) {
      if (param != current) {
        this.$router.replace({name: 'pessoas', query: {paper: param}})
      } else {
        this.$router.replace({name: 'pessoas'})
      }
    }
  }
}
</script>

